import { Component, OnInit } from '@angular/core';
import { copyRight } from '../../providers/config'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {

  copyRightYear;

  // set the currenr year
  year: number = new Date().getFullYear();

  constructor() {
    this.copyRightYear = copyRight;
  }

  ngOnInit() {
  }

}
