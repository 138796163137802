import { Injectable } from '@angular/core';
import { appConfiguration } from "../providers/config";

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() {
  }
  log(msg){
    if(appConfiguration.ENVIRONMENT === "LOCAL" || appConfiguration.ENVIRONMENT === "DEV"){
      console.log(msg);
    }
  }
}
