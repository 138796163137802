import { Injectable } from '@angular/core';
import { CART } from '../../../providers/Utils/data'
import {LoggerService} from "../../../services/logger.service";
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private logger:LoggerService, private router:Router) { }

  addProductToCart(item) {
    if(CART.stores.length < 1){
      this.router.navigate(["/app/products-publishing/online-stores"]);
      return;
    }

    var index= CART.items.map(function(x) {return x.parentProductId; }).indexOf(item.parentProductId);
    this.logger.log("Index to product to be added : " + index);
    if(index != -1){
      this.logger.log("Already added");
    }
    else {
      CART.items.push(item);
    }
    this.persistLocal();
  }

  deleteProductFromCart(item){
    var index= CART.items.map(function(x) {return x.parentProductId; }).indexOf(item.parentProductId);
    if (index != -1) {
      this.removeOneProduct(index);
    }
  }

  removeOneProduct(index){
    CART.items.splice(index,1)
    if(CART.items.length > 0){
      this.persistLocal();
    }
    else {
      this.logger.log("Length Of cart Is 0");
      CART.stores = [];
      this.unPersistLocal();
    }
  }

  unPersistLocal(){
    localStorage.removeItem("staged-cart" + localStorage.getItem("uuid"));
  }

  persistLocal(){
    localStorage.setItem("staged-cart" + localStorage.getItem("uuid"), JSON.stringify(CART));
  }

  // unPersistStore(){
  //   localStorage.removeItem("staged-cart" + localStorage.getItem("uuid"));
  // }
  //
  // persistStore(){
  //   localStorage.setItem("staged-cart" + localStorage.getItem("uuid"), JSON.stringify(CART));
  // }

  removeAllItemsFromStagedCart(){
    CART.items = [];
    if(CART.items.length > 0){
      this.persistLocal();
    }
    else {
      this.unPersistLocal();
    }
  }

  removeStoreAttached(){
    this.logger.log("remove store attached called : ");
    CART.stores = [];
  }

  getItemsInStagedCart() : any[]{
    return CART.items;
  }

  getStoresInStagedCart() : any[]{
    return CART.stores;
  }

  getItemsLengthInStaggedCart() : any{
    return CART.items.length;
}
}
