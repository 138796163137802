import * as firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import {Router} from '@angular/router';
import {ApiService} from './services/api.service';
import {LoggerService} from './services/logger.service';
import {Injectable} from '@angular/core';
import {appConfiguration} from './providers/config';

@Injectable()
class AuthUtil {
    // constructor(firebaseConfig, private router:Router) {
    constructor(private router:Router, private api:ApiService, private logger:LoggerService) {
        // if (firebaseConfig) {
        //     // Initialize Firebase
        //     firebase.initializeApp(firebaseConfig);
        //     firebase.auth().onAuthStateChanged((user) => {
        //         if (user) {
        //             sessionStorage.setItem(appConfiguration.prefix + 'authUser', JSON.stringify(user));
        //         } else {
        //             sessionStorage.removeItem(appConfiguration.prefix + 'authUser');
        //         }
        //     });
        // }
    }

    /**
     * Registers the user with given details
     */
    registerUser = (userName, email, password, companyName, firstName, lastName, storeUrl, country) => {
        return new Promise((resolve, reject) => {
            this.api.RegisterAdpCustomer(userName, email, password, companyName, firstName, lastName, storeUrl, country).subscribe((user: any) => {
                // var user: any = firebase.auth().currentUser;
                resolve(user);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * Login user with given details
     */
    loginUser = (email, password, storeUrl) => {
        return new Promise((resolve, reject) => {
            // firebase.auth().signInWithEmailAndPassword(email, password).then((user: any) => {
            this.api.loginFromcatalyst(email, password,storeUrl).subscribe(user => {
              // eslint-disable-next-line no-redeclare
              // var user: any = firebase.auth().currentUser;
              // if (user.status === "200") {
                this.setLoggeedInUser(user)
              resolve(user);
            // }
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * forget Password user with given details
     */
    forgetPassword = (email) => {
        return new Promise((resolve, reject) => {
            // tslint:disable-next-line: max-line-length
          this.api.resetPassword(email).subscribe(data => {
            // eslint-disable-next-line no-redeclare
            // var user: any = firebase.auth().currentUser;
            this.logger.log("data in quth util L : " + data);
            // if (data === "200") {
              // this.setLoggeedInUser(user.data.data.data.adpCustomerInfo)
              resolve(data);
            // }
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            this.api.signOut()
              // .then(() => {
              this.removeLoggeedInUser();
                // resolve(true);
            // }).catch((error) => {
            //     reject(this._handleError(error));
            // });
        });
    }

    setLoggeedInUser = (user) => {
        sessionStorage.setItem(appConfiguration.prefix + 'authUser', JSON.stringify(user));
    }

  removeLoggeedInUser = () => {
    this.logger.log("removeLoggeedInUser called in auth util");
    sessionStorage.removeItem(appConfiguration.prefix + 'authUser');
    sessionStorage.clear();
    localStorage.clear();
  }

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!sessionStorage.getItem(appConfiguration.prefix + 'authUser')) {
            return null;
        }
        return JSON.parse(sessionStorage.getItem(appConfiguration.prefix + 'authUser'));
    }

    /**
     * Handle the error
     * @param {*} error
     */
    _handleError(error) {
        // tslint:disable-next-line: prefer-const
        var errorMessage = error.message;
        // return errorMessage;
        this.router.navigate(['/']);
    }
}

// tslint:disable-next-line: variable-name
let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
// const initFirebaseBackend = (config) => {
//     if (!_fireBaseBackend) {
//         _fireBaseBackend = new FirebaseAuthBackend(config);
//     }
//     return _fireBaseBackend;
// };

/**
 * Returns the firebase backend
 */
// const getFirebaseBackend = () => {
//     return _fireBaseBackend;
// };

// export { initFirebaseBackend, getFirebaseBackend };
// export { getFirebaseBackend };
export { AuthUtil };
